<template>
  <div class="login">
    <div class="login-container">
      <div class="login-box">
        <div class="login-header">
          <h2>欢迎回来</h2>
          <p>请登录您的账号</p>
        </div>
        <form @submit.prevent="changeLogin(loginValues)">
          <div class="form-group" v-for="(item, index) of loginValues" :key="index">
            <label>{{ item.title }}</label>
            <div class="input-wrapper">
              <i :class="item.type === 'password' ? 'el-icon-lock' : 'el-icon-message'"></i>
              <input 
                :type="item.type" 
                v-model="item.value"
                :placeholder="'请输入' + item.title"
              />
            </div>
          </div>
          <button 
            class="submit-btn" 
            @click="changeLogin(loginValues)" 
            v-loading="loading"
          >
            登录
          </button>
        </form>
        <div class="login-footer">
          <el-link type="primary" @click="register" class="register-link">
            还没有账号？立即注册
          </el-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import store from "@/store";

export default {
  name: "Login",
  data() {
    return {
      loading: false,
      user: {},
      loginValues: [
        {
          title: "邮箱",
          type: "text",
          value: "1",
        },
        {
          title: "密码",
          type: "password",
          value: "1",
        },
      ],
    };
  },
  methods: {
    register() {
      this.$router.push('/register');
    },
    //   登录按钮
    changeLogin(values) {
      if (!values[0].value.trim()) {
        this.$message.error('请输入邮箱')
        return
      }
      if (!values[1].value.trim()) {
        this.$message.error('请输入密码')
        return
      }
      this.user.username = values[0].value
      this.user.password = values[1].value
      this.loading = true
      axios
          .post(this.$globle.allurl + "user/login", this.user)
          .then((response) => {
            console.log(response)
            this.loading = false
            if (response.data.result !== "") {
              store.commit('setUser', response.data.result);

              this.$router.replace(this.$route.query.redirect || '/');
            } else {
              this.$message({
                type: "fail",
                message: "登录失败"
              });
            }
          })
          .catch((error) => {
            console.log(error);
            console.log("账号密码错误！");
          })
    },
  },
  mounted() {

  },
};
</script>

<style scoped>
.login {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

.login-container {
  width: 100%;
  max-width: 420px;
  padding: 20px;
}

.login-box {
  background: rgba(255, 255, 255, 0.95);
  border-radius: 16px;
  padding: 40px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
}

.login-header {
  text-align: center;
  margin-bottom: 40px;
}

.login-header h2 {
  color: #333;
  font-size: 28px;
  margin-bottom: 8px;
}

.login-header p {
  color: #666;
  font-size: 16px;
}

.form-group {
  margin-bottom: 24px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #333;
  font-size: 14px;
}

.input-wrapper {
  position: relative;
  display: flex;
  align-items: center;
  background: #f5f7fa;
  border-radius: 8px;
  padding: 0 16px;
  transition: all 0.3s ease;
}

.input-wrapper:focus-within {
  background: #fff;
  box-shadow: 0 0 0 2px #409EFF;
}

.input-wrapper i {
  color: #909399;
  margin-right: 12px;
}

.input-wrapper input {
  width: 100%;
  height: 48px;
  border: none;
  background: transparent;
  font-size: 16px;
  color: #333;
  outline: none;
}

.input-wrapper input::placeholder {
  color: #909399;
}

.submit-btn {
  width: 100%;
  height: 48px;
  background: #409EFF;
  border: none;
  border-radius: 8px;
  color: white;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s ease;
}

.submit-btn:hover {
  background: #66b1ff;
  transform: translateY(-1px);
}

.submit-btn:active {
  transform: translateY(0);
}

.login-footer {
  margin-top: 24px;
  text-align: center;
}

.register-link {
  font-size: 14px;
  color: #409EFF;
  text-decoration: none;
  transition: all 0.3s ease;
}

.register-link:hover {
  color: #66b1ff;
  text-decoration: underline;
}
</style>
